import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import loadable from "@loadable/component"
import NavigationBar from "../components/Navbar"
import throttle from "lodash/throttle"

const Footer = loadable(() => import("../components/footer3"))
const SocialMedia = loadable(() => import("../components/social-media"))
const Scroll = loadable(() => import("../components/scroll"))

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges } = data.allMarkdownRemark

  const [postsToShowTags, setpostsToShowTags] = useState(() => {
    if (typeof window !== `undefined`) {
      return window.postsToShowTags || 6
    }
    return 6
  })
  const [showingMore, setShowingMore] = useState(postsToShowTags > 6)

  const throttledUpdate = throttle(update, 200)

  function update() {
    const distanceToBottom =
      document.documentElement.scrollHeight -
      (window.scrollY + window.innerHeight)

    if (showingMore && distanceToBottom < 225) {
      setpostsToShowTags(postsToShowTags + 18)
    }

    throttledUpdate.ticking = false
  }

  const handleScroll = () => {
    if (!throttledUpdate.ticking) {
      throttledUpdate.ticking = true
      requestAnimationFrame(() => update())
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.postsToShowTags = postsToShowTags
    }
  })

  const tagHeader = `${tag}`

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>{tagHeader}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Key computer science tags to explore content in an organized manner."
        />
      </Helmet>
      <div className="sm:sticky top-0 z-50">
        <NavigationBar />
      </div>

      <div className="bg-light-white">
        <h1 className="mx-auto tracking-wider px-4 pt-10 pb-6 text-2xl xl:text-3xl leading-10 text-center text-cool-gray-900 sm:leading-none">
          {tagHeader}
        </h1>

        <section>
          <div className="container mx-auto px-4 md:px-5 2xl:px-6">
            <div className="pt-2">
              <div className="grid my-2 gap-8 2xl:gap-10 md:grid-cols-2 lg:grid-cols-3">
                {edges.slice(0, postsToShowTags).map(({ node }) => {
                  const path = node.frontmatter.path
                  const title = node.frontmatter.title
                  const seotitle = node.frontmatter.seotitle
                  const date = node.frontmatter.date
                  const featuredimage = node.frontmatter.featuredimage
                  const id = node.id

                  return (
                    <Link to={path} className="bg-light-white" key={id}>
                      <div className="flex flex-col h-full shadow-md hover:shadow-xl transition duration-150 rounded-2xl border">
                        {featuredimage && (
                          <div className="flex mt-3 justify-center">
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                paddingTop: "56.25%",
                              }}
                            >
                              <img
                                className="object-cover pointer-events-none absolute top-0"
                                src={require(`../../static/${featuredimage}`)}
                                alt={title}
                              />
                            </div>
                          </div>
                        )}
                        <div className="flex flex-col tracking-wider  justify-between p-5">
                          <div className="mt-2 text-lg xl:text-xl 2xl:text-2xl leading-6 text-gray-900">
                            {seotitle}
                          </div>
                          <div className="text-sm 2xl:text-base mt-3 text-gray-600">
                            <time dateTime="2020-03-16">{date}</time>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
              {postsToShowTags < edges.length && !showingMore && (
                <div className="flex justify-center mt-12 mb-8">
                  <button
                    id="loadMoreBlog"
                    className="px-5 py-3 bg-white shadow rounded border border-new-purple text-white hover:bg-new-purple text-new-purple hover:text-white transition duration-150"
                    onClick={() => {
                      setpostsToShowTags(
                        prevpostsToShowTags => prevpostsToShowTags + 18
                      )
                      setShowingMore(true)
                    }}
                  >
                    <h3 className="flex mx-auto tracking-wider text-lg 2xl:text-xl">
                      Load More Blogs
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        class="feather feather-arrow-down ml-2"
                      >
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <polyline points="19 12 12 19 5 12"></polyline>
                      </svg>
                    </h3>
                  </button>
                </div>
              )}
            </div>

            {showingMore && <Scroll showBelow={250} />}
            <SocialMedia />
            <div className="mb-6 mt-6">
              <Footer />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String, $postsToShowTags: Int) {
    allMarkdownRemark(
      limit: $postsToShowTags
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: [$tag] }, visibility: { eq: true } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            path
            date(formatString: "DD MMMM YYYY")
            title
            seotitle
            featuredimage
            visibility
          }
        }
      }
    }
  }
`
